var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-page',{attrs:{"page-title":"Add Packages"},scopedSlots:_vm._u([{key:"page-header",fn:function(){return [_c('h2',{staticClass:"page-title"},[_vm._v("Product Package")]),_c('r-menu-tab',{attrs:{"level":3}})]},proxy:true},{key:"action-bar",fn:function(){return [_c('b-breadcrumb',{attrs:{"separator":"has-succeeds-separator","size":"is-small"}},[_c('b-breadcrumb-item',{attrs:{"tag":"router-link","to":("/packages/" + (_vm.$route.params.subscriptionType))}},[_vm._v(" Product Package ")]),(!_vm.isEditing)?_c('b-breadcrumb-item',{attrs:{"tag":"router-link","to":"/packages/add-package","active":""}},[_vm._v(" Add Package ")]):_c('b-breadcrumb-item',{attrs:{"tag":"router-link","to":"/packages/edit-package","active":""}},[_vm._v(" Edit Package ")])],1)]},proxy:true},{key:"page-content",fn:function(){return [(_vm.form.isLoading)?_c('Loading',{attrs:{"is-loading":true,"is-full-page":true}}):_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"}),_c('div',{staticClass:"column is-4"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14px-normal form-timeoff-type",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"is-flex"},[_c('div',{staticClass:"column is-12 voucher-title"},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Edit Package' : 'Add Package')+" ")]),_c('div',{staticClass:"is-flex-direction-row is-flex is-12 mt-4"},[_c('div',[_c('b-switch',{staticClass:"voucher-quota-limit",attrs:{"type":"is-success"},model:{value:(_vm.form.isHighlighted),callback:function ($$v) {_vm.$set(_vm.form, "isHighlighted", $$v)},expression:"form.isHighlighted"}})],1),_c('div',{staticStyle:{"width":"10rem"}},[_vm._v("Highlight Package")])])]),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"package name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name Package","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Enter Package Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"Description 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Description 1","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Enter Description"},model:{value:(_vm.form.description1),callback:function ($$v) {_vm.$set(_vm.form, "description1", $$v)},expression:"form.description1"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"Description 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Description 2","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Enter Description"},model:{value:(_vm.form.description2),callback:function ($$v) {_vm.$set(_vm.form, "description2", $$v)},expression:"form.description2"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"mb-2 has-text-weight-bold"},[_vm._v("Discount")]),_c('b-switch',{staticClass:"voucher-quota-limit",attrs:{"left-label":true,"type":"is-success"},on:{"input":_vm.switchToggle},model:{value:(_vm.isDiscountOn),callback:function ($$v) {_vm.isDiscountOn=$$v},expression:"isDiscountOn"}}),_c('ValidationProvider',{attrs:{"name":"discount","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"disabled":!_vm.isDiscountOn,"expanded":"","icon-right":"percent-outline","type":"number"},model:{value:(_vm.form.discountAmount),callback:function ($$v) {_vm.$set(_vm.form, "discountAmount", $$v)},expression:"form.discountAmount"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Price","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"0","type":"number"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"max user","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Max User","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"0","type":"number"},model:{value:(_vm.form.maxUser),callback:function ($$v) {_vm.$set(_vm.form, "maxUser", $$v)},expression:"form.maxUser"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"mb-2 has-text-weight-bold"},[_vm._v("Label Tag")]),_c('b-switch',{staticClass:"voucher-quota-limit",attrs:{"left-label":true,"type":"is-success"},model:{value:(_vm.isLabelTagOn),callback:function ($$v) {_vm.isLabelTagOn=$$v},expression:"isLabelTagOn"}}),_c('ValidationProvider',{attrs:{"name":"label tag","rules":_vm.isLabelTagOn ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-autocomplete',{attrs:{"data":_vm.listTag,"placeholder":"Tag","field":"name","loading":_vm.isFetchingTag,"check-infinite-scroll":true,"icon-right":"pound","disabled":!_vm.isLabelTagOn},on:{"typing":_vm.getTagList,"select":_vm.selectTag,"infinite-scroll":_vm.getMoreTagList},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_vm._v(" "+_vm._s(props.option.name)+" ")])])]}}],null,true),model:{value:(_vm.form.tagId),callback:function ($$v) {_vm.$set(_vm.form, "tagId", $$v)},expression:"form.tagId"}})],1),(
                      _vm.isLabelTagOn &&
                      _vm.listTag < 1 &&
                      _vm.form.tagId !== null &&
                      _vm.form.tagId.length > 1 &&
                      !_vm.selectedTag.id
                    )?_c('div',{staticClass:"label-warning"},[_vm._v(" The Label tag not found ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('span',{staticClass:"is-pulled-right"},[_c('b-button',{attrs:{"type":"is-primary","outlined":"","tag":"router-link","to":("/packages/" + (_vm.$route.params.subscriptionType))}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"loading":_vm.isLoading,"native-type":"submit","type":"is-primary","disabled":(_vm.isLabelTagOn &&
                        _vm.listTag < 1 &&
                        _vm.form.tagId.length > 1 &&
                        !_vm.selectedTag.id) ||
                      _vm.isLoading}},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Save' : 'Add')+" ")])],1)])])])]}}])})],1),_c('div',{staticClass:"column is-4"})])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }